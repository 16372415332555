<template>
  <!-- 顶部滑动日历组件，参数略多看下方注释 -->
  <div class="slider-cont">
    <div class="slider-x" id="slider-z">
      <div v-for="(item,index) of dates" :key="index" @click="change(item)">
        <div :class="'basesize '+(curDate==item?'active':'')">
          <span class="date-text">{{item | comverTime('MM-DD')}}</span>
          <span class="week-text">{{item | comverTime('ddd')}}</span>
        </div>
      </div>
      <div class="calen-btn" @click="open">
        <van-icon name="calender-o" />
        <span class="date-text">日历</span>
      </div>
    </div>
  </div>
</template>

<script>
// 依赖
import moment from "moment";
import { DiffDate, AddDate } from "@/services/date.js";
export default {
  name: "sliderDate",
  props: {
    // 选中日期[Date]
    cur: {
      default: () => moment().format("YYYY-MM-DD")
    },
    // 开始日期[Date]
    start: {
      default: () => moment().format("YYYY-MM-DD")
    },
    // 日期范围[Int]  特殊情况：cur-start>range时  range失效，新的区间为[start,cur]
    range: {
      default: () => 30
    }
  },
  computed: {
    // 选中日期，格式化后
    curDate() {
      this.fixScroll(500);
      return moment(this.cur).format("YYYY-MM-DD");
    },
    // 日期范围的起点，格式化后
    startDate() {
      return moment(this.start).format("YYYY-MM-DD");
    },
    // 生成日期数组，startdate开始 ....range个
    dates() {
      let i = Math.max(
        this.range,
        DiffDate(this.startDate, this.curDate) + 1,
        this.len
      );
      let max = i;
      let arr = [];
      while (i > 0) {
        arr.push(
          moment(this.startDate, "YYYY-MM-DD")
            .add(max - i--, "days")
            .format("YYYY-MM-DD")
        );
      }
      this.len = Math.max(arr.length, this.len);
      return arr;
    }
  },
  data() {
    return {
      len: 0 // 长度记录
    };
  },
  mounted() {
    // 观察者
    var targetNode = document.getElementById("slider-z");

    var config = { attributes: true, childList: true, subtree: true };

    var callback = mutationsList => {
      this.fixScroll(500);
    };

    var observer = new MutationObserver(callback);

    observer.observe(targetNode, config);
  },
  activated() {
    this.fixScroll();
  },
  methods: {
    // 矫正位置
    fixScroll(time = 1000) {
      setTimeout(() => {
        if (!document.querySelector(".slider-x>div")) return;
        let domWidth = document.querySelector(".slider-x>div").offsetWidth*2; // 一个格子的宽度
        let visualWidth = window.innerWidth - domWidth * 1.2; // 可是宽度

        let index = 0; // 当前位置下标,下方为了绑定
        if (this.dates[0]) index = DiffDate(this.dates[0], this.curDate);
        if (this.startDate) index = DiffDate(this.startDate, this.curDate);

        let rangeStart = document.querySelector(".slider-x .active").offsetLeft-window.innerWidth+domWidth; // 合法范围 - 左
        let rangeEnd = document.querySelector(".slider-x .active").offsetLeft; // 合法范围 - 右
        let rangeCur = document.querySelector(".slider-x").scrollLeft; // 当前可视区域

        // 如果不在合法区域，调整下
        if (rangeCur > rangeEnd)
          document.querySelector(".slider-x").scrollLeft = rangeEnd;
        if (rangeCur < rangeStart)
          document.querySelector(".slider-x").scrollLeft = rangeStart;
      }, time);
    },
    // 打开日历
    open() {
      this.$emit("open");
    },
    // 触发父事件
    change(date) {
      if (date != this.curDate) {
        this.$emit("changeDate", date);
      }
    }
  }
};
</script>


<style lang="less" scoped>
@import "../styles/theme.less";
@basesize: 50px; // 基准尺寸
.slider-cont {
  padding-right: @basesize+2px;
}
.slider-x {
  box-sizing: border-box;
  width: 100%;
  overflow: scroll;
  display: flex;
  padding: 0 4px;
  div {
    width: @basesize;
    height: @basesize;
    padding: 2px 0 2px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: @font-size-xs;
    flex-direction: column;
    box-sizing: border-box;
    span {
      line-height: 12px;
      color: #fff;
      font {
        display: inline-block;
        margin-right: -4px;
      }
    }
    .week-text,
    .date-text {
      white-space: nowrap;
    }
    .week-text,
    .price {
      padding-top: 2px;
    }
  }
  .active {
    background: #fff;
    span {
      line-height: 12px;
      color: @blue;
    }
  }
  .calen-btn {
    height: @basesize;
    min-width: @basesize;
    position: absolute;
    z-index: 2;
    background-color: darken(@blue, 16%);
    top: 0;
    right: 0;
    box-sizing: border-box;
    font-size: 24px;
    border-radius: 0;
    border-left: 1px solid;
    color: #fff;
    .date-text {
      font-size: @font-size-xs;
    }
  }
}
.basesize {
  min-width: @basesize;
}
.h {
  width: 44px;
  height: 1px;
}
</style>
